<template>
    <div class="shadow-lg p-3 mb-5 mt-5 container">
        <h1>Schützenbuch <img
            src="/Bilder/book.png"
            width="100"
            height="100"
            class="rounded-circle"/></h1>

        <br>
        <h2> Hallo {{ getUserName }}</h2>
        <br>

        <br>
        <div class="form-group row">
            <div
                class="col-sm-10 mb-5 pt-3"
                v-html="getClubWelcomeText">
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: "vwelcome",
  computed: {
    getUserName() {
      return this.$store.getters['ClubAuth/isClubName'];
    },
    getClubWelcomeText() {
      return this.$store.state.ClubApp.TextVwelcome;
    },
  },
}
</script>

<style scoped>

</style>
